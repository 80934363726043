import React from "react"
import styled from "styled-components"

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridColumns};
  align-content: center;
  justify-content: ${props => props.justifyContent};
  margin: ${props => (props.margin ? props.margin : `100px 0`)};
  font-family: "Montserrat", sans-serif;
  grid-column-gap: 40px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const ImageSection = styled.div`
  display: grid;
  grid-template-columns: ${props => props.imageGridColumn};
  justify-content: center;
  align-content: center;
  grid-area: ${props => props.alignImage};
  @media (max-width: 768px) {
    grid-template-columns: 80%;
  }
`

const TextContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${props => props.textGridColumn};
  justify-content: center;
  grid-area: ${props => props.alignText};
  @media (max-width: 768px) {
    margin-top: 25px;
    grid-template-columns: 60%;
    text-align: center;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    padding: 0 20px;
  }
`
const ContentH1 = styled.h1`
  font-size: 42px;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const ContentH2 = styled.div`
  font-size: 22px;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 1450px) {
    font-size: 18px;
  }
`

const ContentP = styled.p`
  font-size: 18px;
  color: #3d4d69;
  margin: 15px 0;
  line-height: 1.5;
  letter-spacing: .3px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

export default props => (
  <ContentContainer
    gridColumns={props.gridColumns}
    justifyContent={props.justifyContent}
    margin={props.margin}
  >
    <ImageSection
      alignImage={props.imageAlign}
      imageGridColumn={props.imageGridColumn}
    >
      <div>{props.image}</div>
    </ImageSection>
    <TextContainer
      alignText={props.textAlign}
      textGridColumn={props.textGridColumn}
    >
      <div>
        {props.contentH1 && <ContentH1> {props.contentH1} </ContentH1>}
        <ContentH2>{props.contentH2}</ContentH2>
        {props.contentP1 !== "" && <ContentP>{props.contentP1} </ContentP>}
        {props.contentP2 !== "" && <ContentP>{props.contentP2} </ContentP>}
        {props.contentP3 !== "" && <ContentP>{props.contentP3} </ContentP>}
      </div>
    </TextContainer>
  </ContentContainer>
)
